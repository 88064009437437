import React, {useContext, useEffect, useState} from 'react';
import {mercheryFetch} from 'src/scripts/fetchConstructor';
import {toastUp, validateResponse} from 'src/scripts/functions';
import {useAppSelector} from 'src/scripts/pre-type/use-selector';
import {useTabIndex} from 'src/scripts/hooks/use-tabindex';
import MyInput from '../../../../../_utility-components/input';
import { OrderDelivery, RussiandPostTariffs, RussianPostCalcDto, RussianPostCalcResponseDto, RussianPostExtendedTariff } from 'merchery-lib';
import {GetTariffs, OrderDeliveryContext} from '../delivery';
import {typeCategoryMapping} from "./dto/postprice.ru.utils";
import MyButton from "../../../../../_utility-components/button/button";
import {
  RussianPostCreateAdminFrontData, RussianPostCreateResAndUpdatedDelivery,
} from "./dto/rpost-create.dto";
import {useLoad} from "src/scripts/hooks/use-load";
import {OrderContext} from "../../../order-page";
import {RussianPostTariffsTable} from "./tariffs-table";
import {CountryCode} from "./country-code";
import {useItemsMass} from "src/scripts/hooks/use-items-mass";
import {DeliveryDimensions} from "../delivery-dimensions-presets";
import BBPromise from 'bluebird';
import {batch} from "react-redux";
import useMounted from "src/scripts/hooks/use-mounted";

export const RussianPostContext = React.createContext<{
  tariffs: RussianPostExtendedTariff[],
  currentTariff: RussianPostExtendedTariff | undefined,
  setCurrentTariff: (tariff: RussianPostExtendedTariff | undefined) => void,
  tariffsLoading: boolean,
  courier: boolean,
  setCourier: (bool: boolean) => void,
  fragile: boolean,
  setFragile: (bool: boolean) => void,
}>({
  tariffs: [],
  currentTariff: undefined,
  setCurrentTariff: () => {},
  tariffsLoading: false,
  courier: false,
  setCourier: () => {},
  fragile: false,
  setFragile: () => {},
});

function RussianPostEms () {
  const _isMounted = useMounted()
  const items = useAppSelector(state => state.productItemsInContext);
  const settings = useAppSelector(state => state.settings);
  const storePostcode = settings.find(setting => setting.callname === 'store_postcode')?.value as number | null;
  const russianPostTariffs = settings.find(setting => setting.callname === 'russian_post_tariffs_in_used')?.value as RussiandPostTariffs | null;
  
  const [tariffs, setTariffs] = useState<RussianPostExtendedTariff[]>([]);
  const [currentTariff, setCurrentTariff] = useState<RussianPostExtendedTariff | undefined>(undefined);
  const [tariffsLoading, setTariffsLoading] = useState(false);
  const [courier, setCourier] = useState(false);
  const [fragile, setFragile] = useState(false);

  const itemsMass = useItemsMass();

  const {
    orderChange,
  } = useContext(OrderContext);

  const {
    delivery,
    changeSelectedDelivery,
    safeHandler,
    setDeliveryEditState,
    updateOrderDeliveriesList,
  } = useContext(OrderDeliveryContext);

  const {
    order
  } = useContext(OrderContext);

  const tabIndex = useTabIndex();
  const [, setLoad] = useLoad();

  useEffect(() => {
    if(items.length && delivery?.postcode && itemsMass !== 0) {
      getTariffs()
    }
  }, [itemsMass]);

  const getTariffs: GetTariffs = async () => {
    if(!delivery) {
      return false
    }

    const {
      postcode,
      length,
      width,
      height
    } = delivery;

    if(!russianPostTariffs) {
      toastUp('Используемые тарифы Почты России не были заданы');
      return false;
    }

    if(!postcode) {
      toastUp('Не выбран индекс получателя');
      return false;
    }

    if(!items.length) {
      toastUp('Нет товаров для рассчета массы');
      return false;
    }

    if(itemsMass === 0) {
      toastUp('Товары не имеют веса для рассчета веса')
      return false;
    }

    if(!storePostcode) {
      toastUp('Не задан индекс отправления')
      return false;
    }

    if(!length || !width || !height) {
      toastUp('Не заданы габариты упаковки')
      return false;
    }

    setTariffsLoading(true)

    const newTariffs: (RussianPostExtendedTariff | null)[] = await BBPromise.map(
      russianPostTariffs,
      async(tariff) => {
        const params: RussianPostCalcDto = {
          "index-from": String(storePostcode),
          "index-to": postcode,
          "mass": itemsMass,
          dimension: {
            length: length,
            width: width,
            height: height
          },
          ...(delivery.country_iso_num_code && {
            "mail-direct": delivery.country_iso_num_code,
          }),
          'mail-category': tariff['mail-category'],
          'mail-type': tariff['mail-type']
        }

        const res = await mercheryFetch<RussianPostCalcResponseDto>(
          'integrations/russian-post/calc',
          'POST',
          params,
        )

        if(!_isMounted.current || !validateResponse(res)) {
          return null
        }

        const extendedTariff: RussianPostExtendedTariff = {
          ...res.records,
          ...params,
          'tariff-id': `${tariff["mail-category"]}_${tariff["mail-type"]}`
        }

        return extendedTariff
      },
      { concurrency: 1 },
    );

    setTariffsLoading(false)

    if(_isMounted.current) {
      setTariffs(
        newTariffs.filter(tariff => tariff !== null)
      )
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    label: keyof OrderDelivery 
  ) => {
    const value = e.target.value;

    changeSelectedDelivery({[label]: value});
  }

  const registerDelivery = async () => {
    const actualDelivery = await safeHandler(true) || delivery;

    if(!actualDelivery) {
      toastUp('error');
      return false
    }

    if(!actualDelivery.country_iso_num_code) {
      toastUp('Не выбрана страна доставки')
      return false
    }

    if(!order?.client) {
      toastUp('Для регистрации необходима информация о клиенте')
      return false
    }

    if(!items.length) {
      toastUp('Сначала необходимо добавить товары в заказ')
      return false
    }

    if(!currentTariff) {
      toastUp('Не выбран тариф')
      return false
    }

    if(!actualDelivery.address) {
      toastUp('Не выбран адрес')
      return false
    }

    const {
      category,
      type
    } = typeCategoryMapping[currentTariff['tariff-id']];

    const body: RussianPostCreateAdminFrontData = {
      'mail-category': category,
      'mail-type': type,
      courier: false,
      deliveryId: actualDelivery.id
    }

    setLoad(true)

    const res = await mercheryFetch<RussianPostCreateResAndUpdatedDelivery>(
      'integrations/russian-post/register',
      'POST',
      body,
    )

    setLoad(false)

    if(!_isMounted.current || !validateResponse(res)) {
      return false
    }

    const deliveryChanges = {
    ...actualDelivery,
    ...res.records.deliveryChanges
    }

    batch(() => {
      setDeliveryEditState(false)
      updateOrderDeliveriesList(deliveryChanges)
      orderChange({
        current_delivery_id: deliveryChanges.id
      })
    })
  }

  const calcCostDisabled = !delivery ||
    !delivery.city ||
    !delivery.address ||
    !delivery.postcode;

  return (
    <RussianPostContext.Provider value={{
      tariffs,
      tariffsLoading,
      courier,
      setCourier,
      fragile,
      setFragile,
      currentTariff,
      setCurrentTariff
    }}>
      <div className="op-delivery-russian-post op-delivery-extended">
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          gap: '8px'
        }}>
          <DeliveryDimensions />
        </div>

        {/*<div className="wide-text-font-xxs">*/}
        {/*  второстепенные параметры доставки*/}
        {/*</div>*/}

        {/*<RussianPostSecondaryDeliveryOptions/>*/}

        {/*<WidgetWrapper/>*/}

        <div className="wide-text-font-xxs" id="pochta-error-container">
          адрес доставки
        </div>

        <div className="delivery-address-inputs">
          <CountryCode/>

          <MyInput
            id="delivery-city"
            myClassName='delivery-city-input'
            placeholder="Наименование города"
            value={delivery?.city || ''}
            onChange={(e) => handleChange(e, 'city')}
            tabIndex={tabIndex}
          />

          <MyInput
            id="delivery-postcode"
            myClassName='delivery-postcode-input'
            placeholder="Индекс"
            value={delivery?.postcode || ''}
            onChange={(e) => handleChange(e, 'postcode')}
            tabIndex={tabIndex}
          />

          {/* <MyInput autocomplete='off' name="delivery-address" placeholder="Адрес" value={this.state.address} onChange={(e) => this.handleChange(e, 'address')} /> */}
        </div>

        <MyInput
          name="delivery-address"
          myClassName='delivery-address-input'
          placeholder="Адрес"
          required={false}
          value={delivery?.address || ''}
          onChange={(e) => handleChange(e, 'address')}
          tabIndex={tabIndex}
        />

        <div className={''}>
          <MyButton
            className='blue-btn'
            disabled={calcCostDisabled}
            onClick={() => getTariffs()}
          >
            Рассчитать стоимость
          </MyButton>
        </div>

        {/*<div className="flex-wrapper">*/}
        {/*  <div className="wide-text-font-xxs">*/}
        {/*    место отправления*/}
        {/*  </div>*/}

        {/*  <div className="wide-text-font-xxs">*/}
        {/*    цена доставки*/}
        {/*  </div>*/}
        {/*</div>*/}

        <RussianPostTariffsTable/>

        {/*<div className="flex-wrapper">*/}
        {/*<DeliveryFromSelect*/}
        {/*  fromHandler={() => fromHandler(getTariffs)}*/}
        {/*/>*/}

        {/*<DeliveryPriceAndMode*/}
        {/*  tariffHandler={tariffHandler}*/}
        {/*  MainActionBtnText={PochtaTariffHandler}*/}
        {/*  openBtnText={*/}
        {/*    currentTariff?.price*/}
        {/*      ? <span>{tariffNames[currentTariff.id as keyof TariffNames]}</span>*/}
        {/*      : <span className="blue-color">Выбрать тариф</span>*/}
        {/*  }*/}
        {/*/>*/}
        {/*</div>*/}

        {currentTariff ?
          <div>
            <MyButton
              className='blue-btn'
              disabled={!currentTariff}
              onClick={() => registerDelivery()}
            >
              Зарегистрировать заказ
            </MyButton>
          </div>
        : null}
      </div>
    </RussianPostContext.Provider>
  )
}

export default RussianPostEms

